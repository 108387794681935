/* ------------ start 24/theme.css ------------ */
/* primary styles */
a, a:visited, a:active #paginationsearch, #externalpaginationsearch, #subheader, a.button_style_send {
	color:#e7802e;
}
a.button_style, .buttonunavailable, .buttonunavailable:hover, .paginationon {
	background-color:#e7802e!important;
}
body, input, select, textarea {
	color: #3d3d3d;
}
h1, h2, h3 {
	color:#1d5caa;
}

html {color:#3d3d3d;}

.essaypagecontent  {color:#3d3d3d !important;} 
a:hover {
	color:#cc7229;
}
#subheader {
	background-color:#deebf2;
}
#logo {
	height:40px;
	width:300px;
	padding-top:32px;
}

#footercontent p {color:#3d3d3d !important;}


#accountarea {color:#3d3d3d;}

.reportconcerncontentmiddleleft p {color:#3d3d3d !important;}

.lightgrayreportconcern {color:#3d3d3d !important;}

.yesnomaybeleft h1 {color:#1d5caa;}

#header {
    background: #123b6d;
    border-bottom: solid 4px #1d5daa;
}
.homeon {
	background-image: asset-site-url("images/homeon.png") !important;
}
.homeover {
	background-image: asset-site-url("images/homeon.png");
}
.homeoff {
	background-image: asset-site-url("images/homeoff.png");
}
.inboxon {
	background-image: asset-site-url("images/inboxon.png") !important;
}
.inboxover {
	background-image: asset-site-url("images/inboxon.png");
}
.inboxoff {
	background-image: asset-site-url("images/inboxoff.png");
}
.findon {
	background-image: asset-site-url("images/findon.png") !important;
}
.findover {
	background-image: asset-site-url("images/findon.png");
}
.findoff {
	background-image: asset-site-url("images/findoff.png");
}
.connecton {
	background-image: asset-site-url("images/connecton.png")!important;
}
.connectover {
	background-image: asset-site-url("images/connecton.png");
}
.connectoff {
	background-image: asset-site-url("images/connectoff.png");
}
#rightheader a, .loginlinkexternal, #externalheaderloginrow2 a {
	color:#e7802e !important;
}
.submenuhover {
	background-color:#1d5caa !important;
}
#helpandadvicelink, #usernamelink {
	background-color:#1d5caa;
	border:solid 1px #1d5caa;
}
#faqlink, #magazinelink, #safetylink, #profilelink, #photolink, #accountlink, #chatlink {
	background-color:#236fcc;
	border:solid 1px #1d5caa;
}
#footer {
	background-color:#f0f0f0;
}
a.button_style:hover {
	background-color:#cc7229!important;
	color:#f1dabf;
}
/* rtn styles */
.realtimenotification {
	border:solid 2px #175a66;
	background-color:#247b8b;
}
.rtnToolbar {
	border:solid 1px #375d6c;
	background-color:#deebf2;
	padding:4px;
}
.rtnmToolbar p {
	color:#375d6c;
}
.rtntToolbar {
	color:#2d1d40!important;
	font-weight:normal!important;
}
.rtnmessaging a {
	color:#f67d21;
}
/* pop up styles */
.popupshapestyle {
	border: 2px solid #1d5caa;
}
/* inbox styles */
.mrcbanner {
	background-image: asset-site-url("images/mrc/mrcbannerinbox.gif");
}
.messagebanner {
	background-image: asset-site-url("images/mrc/messagebannerinbox.png");
}
.profilebanner {
	background-image: asset-site-url("images/mrc/profilebannerinbox.png");
}
.mrcsearchsent {
	background-image: asset-site-url("images/mrc/mrcflirtsentbg.gif");
}
.messagesearchsent {
	background-image: asset-site-url("images/mrc/messageHighlightBg.png");
}
.profilesearchsent {
	background-image: asset-site-url("images/mrc/profileHighlightBg.png");
}
#profileoverlaybirthday, #profileoverlay2 {
	background-image: asset-site-url("images/orangeoverlay.png");
}
/* connect styles */
.connectstarburstfloat, .bluestarburstyes, .bluestarburstfavorites, .bluestarburstviews, .bluestarburstmatches {
	background-image: asset-site-url("images/connect/starburst.png");
}
.starlink {
	background-image: asset-site-url("images/connect/starburst_small.png");
}
/* homepage styles */
.todaysmatchesbannerleft {
	background-image: asset-site-url("images/homepage/todaysmatchestriangle.png")
}
.todaysmatchesbannerright {
	background-color:#1d5caa;
}
.retentionbanner1left img {
	border-color:#1d5caa;
}
.retentioncountdown {
	background-color: #fedfbf!important;
}
#newmembersspace {
	background-color: #f3f3f3;
}
#newmembersspace h1 {
	color: #1d5caa;
}


#photouploadbanner {
	background-color: #74b3c0;
}
#photouploadbanner .inner {
	color:#fff;
}
.retentionbanner1, .retentionbanner5, .retentionbanner6 {
	background-image: asset-site-url("images/homepage/bannernoicon.gif");
}
.retentionbanner2 {
	background-image: asset-site-url("images/homepage/bannerflirtmessageicon.gif");
}
.retentionbanner3, .retentionbanner7 {
	background-image: asset-site-url("images/homepage/bannermessageicon.gif");
}
.retentionbanner4 {
	background-image: asset-site-url("images/homepage/bannerflirticon.gif");
}
.retentionbanner8 {
	background-image: asset-site-url("images/homepage/bannertimericon.gif");
}
.retentionbanner9 {
	background-image: asset-site-url("images/homepage/banneremailicon.gif");
}
.retentionbanner10 {
	background-image: asset-site-url("images/homepage/bannerstarsicon.gif");
}
.draftmesssagenotification, div.echoupgrade {
	background-image: asset-site-url("images/homepage/draftheader.png");
}
/* external */
.favoriteclicked {
	background-color:#cc7229 !important;
}
.externalwomenbrowsebottom, .externalstatesearchbottom {
	background-color:#F3F3F3;
}
.externalstatesearchbottom a { color:#000 }
/* astrologer */
.astrology {
	display:block;
}

.externalmeetwomenright p {color:#3d3d3d;}


.astrologydescriptionheading {
	background-color:#1d5e6b;
}
/* upgrade */
.brightorange1 {
	background-image: asset-site-url("images/upgrade/upgradegradient.gif");
}
.brightorange1 a {
	color:#e7802e;
}
.upgradeoption1capiton .box {
	background-color:#55a77c;
}
/* theme styles */
.color-main {
	color:#e7802e!important;
}
.color-alt {
	color:#1d5caa!important;
}
.color-highlight {
	color:#55a77c!important;
}
.color-highlight-dark {
	color:#55a77c!important;
}
.color-icon {
	color:#55a77c!important;
}
.color-img {
	color:#55a77c!important;
}
.color-upgrade {
	color:#3d3d3d;
}
.color-reactivate {
	color:#1d5caa;
}



.profiletabon {color:#3d3d3d !important;}

#profiletitle {color:#55a77c !important;}

#profiletitle a {color:#55a77c !important;}

#rightheader a {color:#f58e3c !important;}

#profileoverlaybirthday p {color:#FFF;}


.bgcolor-main {
	background-color:#f3f3f3;
}
.bgcolor-alt {
	background-color:#dfdfdf;
}
.bgcolor-icon {
	background-color:#55a77c!important;
}
.bgcolor-input {
	background-color:#FFF!important;
}
.bgcolor-upgrade {
	background-color:#1d5caa!important;
}
.bgcolor-highlight-dark {
	background-color:#55a77c!important;
}
.bgcolor-login {
	background-color:#FFF!important;
}
.bgcolor-reactivate {
	background-color:#1d5caa;
}
.border-color-main {
	border-color:#1d5caa!important;
}

.externalmeetwomenright p {color:#3d3d3d !important;}

.externalsearcharticles a {color:#3d3d3d !important;}


.border-color-alt {
	border-color: #e7802e!important;
}
.border-color-input {
	border-color: #dfdfdf!important;
}
.border-color-upgrade {
	border-color:#1d5caa!important;
}
/* profile edit */
div.profileedit div.interestData:hover > div:first-child, div.profileedit div.interestData:hover > div:first-child p {
	color: #e7802e!important;
}
div.profileedit .getStarted:after {
	content:"Get Started!";
	color:#55a77c;
}
/* notifications toolbar */
.notificationstoolbar {
	background-image: asset-site-url("images/notificationstoolbar/bottomdrawerbg.png");
}
.tbNextStepsDisplay {
	background-color: #deebed;
}
/* todaysmatches 5 test */

.todaysmatches5test {
	padding-left:67px;
	margin-top:7px !important;
}
/* reply rate styles */

.hightreplaystar {
	margin-left:5px;
	margin-right:5px;
}
.highreplierlink {
}
.highreplierlink a {
	color:#3d3d3d;
	text-decoration:underline;
	font-size:10px;
	font-weight:bold;
}
.highreplierheadline {
	color:#1d5caa;
	font-size:12px;
	font-weight:bold;
}
.highreplysearcharea {
	width:102px;
	height:20px;
	display:inline;
	float:left;
	background-image: asset-site-url("images/icons/greatreplierbg.png");
	margin-top:-2px;
	margin-left:8px;
	text-align:center;
}
.highreplysearcharea a {
	color:#1d5caa;
	font-size:10px;
	font-weight:bold;
}
.highreplysearcharea p {
	width:97px;
	text-align:center;
}
.highreplayinboxtitle {
	width:159px;
	text-align:center;
	display:inline;
	float:left;
}
.highreplayinboxtitle a {
	color:#1d5caa;
	font-size:11px;
	font-weight:bold;
}
.highreplayinboxlink {
	display:inline;
	float:left;
	margin-left:10px;
}
#highreplayinbox {
	width:260px;
	display:inline;
	float:left;
	margin-top:16px;
	margin-left:24px;
}
.highreplayinboxarea {
	width:165px;
	height:20px;
	display:inline;
	float:left;
	background-image: asset-site-url("images/icons/greatreplierbg2.png");
	margin-top:0px;
	margin-left:6px;
	text-align:center;
}
#lowreplayinbox {
	display:inline;
	float:left;
	width:250px;
	margin-top:8px;
	margin-left:25px;
}
#lowreplayinbox p {
	display:inline;
	float:left;
}
#lowreplayinbox img {
	display:inline;
	float:left;
}
.lowreplayinboxred {
	background-color:#bf2726;
	color:#FFFFFF;
	padding:4px;
}
.lowreplayinboxwhite {
	background-color:#FFFFFF;
	color:#bf2726;
	padding:3px 4px 3px 4px;
	border:none;
	border-top:solid 1px #bf2726;
	border-bottom:solid 1px #bf2726;
}
.lowreplayinboxlink {
	margin-top:4px;
	margin-left:6px;
}
.lowreplyoverlay {
	display: block;
	left: 154px;
	padding-bottom: 20px;
	position: absolute;
	top: 70px;
	width: 520px;
	z-index: 54001;
}
.lowreplycontent {
	margin-left: 30px;
	margin-top: 25px;
	width: 460px;
}
.lowreplycontent h1 {
	font-size: 24px;
	line-height:26px;
	font-weight: bold;
	margin-bottom:16px;
}
.lowreplycontent p {
	margin-bottom:10px;
}
.highreplyoverlay {
	display: block;
	left: 170px;
	padding-bottom: 20px;
	position: absolute;
	top: 70px;
	width: 520px;
	z-index: 54001;
}
.highreplycontent {
	margin-left: 30px;
	margin-top: 25px;
	width: 460px;
}
.highreplycontent h1 {
	font-size: 24px;
	line-height:26px;
	font-weight: bold;
	margin-bottom:16px;
}
.highreplycontent p {
	margin-bottom:10px;
}
/* change password styles start */

.passwordchangeoverlay {
    display: block;
    left: -290px;
    position: absolute;
    top: -150px;
    width: 573px;
    z-index: 54001;
	padding-bottom:20px;
}

.passwordchangebtn {
    clear: both;
    float: right;
    margin-top: 15px;
}

.passwordchangecontent {width:500px;  margin-left:35px; margin-top:30px; }

.passwordchangecontent h1 {font-size:20px; font-weight:bold;}

.passwordchangecontent h2 {font-size:18px; margin-top:6px;}

.zippasswordchange {
    border: 1px solid;
    font-size: 14px;
    height: 20px;
    padding: 2px;
    width: 100px;
}

.emailpasswordchange {
    border: 1px solid;
    font-size: 14px;
    height: 20px;
    padding: 2px;
    width: 130px;
}

.passwordpasswordchange {
    border: 1px solid;
    font-size: 14px;
    height: 20px;
    padding: 2px;
    width: 150px;
	font-family:Arial, Helvetica, sans-serif !important;
}

.credticardpasswordchange {
    border: 1px solid;
    font-size: 14px;
    height: 20px;
    padding: 2px;
    width: 40px;
}

.passwordchangeform1 {display:block; clear:both; margin-top:10px; height:60px;}

.passwordchangeform2 {display:block; clear:both; }

.passwordchangezip  {display:inline; float:left; width:157px;}

.passwordchangebirthday {display:inline; float:left;}

.slashstylepasswordchange {display:inline; float:left; margin-left:5px; margin-right:5px; margin-top:2px;}

.passwordchangebirthday select {display:inline; float:left; font-size:14px; margin-top:2px;}

.passwordchangeemail {display:inline; float:left;}

.passwordchangepassword1 {display:inline; float:left;}

.passwordchangepassword2 {display:inline; float:left;}


.passwordchangecreditcard {display:inline; float:left;}

.passwordchangecreditcard p {display:inline; float:left; margin-left:20px; margin-top:20px;}

.passwordchangecreditcard input {display:inline; float:left; margin-left:10px; margin-top:17px;}

.passwordchangebutton {display:inline; float:right; margin-top:4px;}

.accounterrorpasswordchange {
    background-color: #BF2726;
    clear: both;
    color: #FFFFFF;
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    width: 390px;
	margin-left:-35px;
}

.accounterrorpasswordchange p {
    padding-bottom: 5px;
    padding-top: 3px;
    text-align: center;
}



/* change password styles end */
/* profile pro background images */
.profileproad {
	background-image: asset-site-url("images/profilepro/profileproad.jpg");
}
.profileproadstar {
	background-image: asset-site-url("images/profilepro/profileproadstar.png");
}
#inboxstarburst {
	background-image: asset-site-url("images/redstarburst.png");
}
#findstarburst {
	background-image: asset-site-url("images/redstarburst.png");
}
#connectstarburst {
	background-image: asset-site-url("images/redstarburst.png");
}
.upgradereadandreply {
	background-image: asset-site-url("images/upgrade/readandreplybg.gif");
}
.wantmorematchesleft {
	width:200px;
	display:inline;
	float:left;
	text-align:center;
	margin-top:25px;
	padding-bottom:25px;
}
.wantmorematchesleft img {
	margin-left:auto;
	margin-right:auto;
}
.wantmorematchescenter {
	width:370px;
	display:inline;
	float:left;
	margin-top:20px;
}
.wantmorematchescenter h1 {
	font-size:28px;
}
.wantmorematchescenter p {
	font-size:18px;
}
.wantmorematchescenter ul {
	margin-left:15px;
	padding:0px;
}
.wantmorematchescenter li {
	font-size:14px;
	padding:0px;
	margin-bottom:7px;
}
.wantmorematchesright {
	display:inline;
	float:left;
	margin-top:165px;
	margin-left:35px;
}
/* homepage mini essay */
.miniessayreviewmessage
{
    color:gray;
    text-align:center;
    margin:0;
    padding-top:10px;
    display:none;
}
.miniessay {
	width:280px;
	border-radius:10px;
	background-color:#f3f3f3;
	margin-top:20px;
	display:block;
	clear:both;
}
.miniessayrow1 {
	display:block;
	clear:both;
	height:40px;
}
.miniessayrow2 {
	display:block;
	clear:both;
	padding-bottom:5px;
}
.miniessayrow3 {
	display:block;
	clear:both;
	height:95px;
}
.miniessayrow4 {
	display:block;
	clear:both;
}
.miniessayrow1 p {
	font-size:20px;
	font-weight:bold;
	color:#1d5caa;
	display:inline;
	float:left;
	margin-left:12px;
	margin-top:9px;
}
.miniessayrow1 img {
	display:inline;
	float:right;
	margin-top:5px;
	margin-right:5px;
}
.miniessayrow2 p {
	color:#1d5caa;
	font-size:16px;
	margin-left:12px;
	margin-bottom:10px;
}
.miniessayrow3 textarea {
	width:231px;
	height:59px;
	background-color:#f7f8f6;
	border:solid 1px #cdd5db;
	margin-left:12px;
	resize:none;
	overflow:auto;
	padding:10px;
}
.miniessayrow4 p {
	display:inline;
	float:left;
}
.miniessayrow4p1 {
	color:#55a77c;
	font-size:14px;
	font-weight:bold;
	margin-top:3px;
	margin-left:106px;
}
.miniessayrow4p2 {
	color:#aea7b5;
	font-size:14px;
	font-weight:bold;
	margin-top:3px;
	margin-left:5px;
}
.miniessayrow4p3 {
	float:right !important;
	margin-right:16px;
}
.miniessayerrorplace {
	position:relative;
}
.miniessayerror {
	position:absolute;
	z-index:47;
	margin-top:22px;
}
.miniessayerrorarrow {
	margin-left:105px;
}
.miniessayerrormessage {
	background-color:#BF2726;
	font-size:16px;

	color:#FFF;
	margin-top:-4px;
	width:274px;
	height:21px;
	padding:3px;
	text-align:center;
	box-shadow: 5px 5px 5px #222;
}
.miniessayerrorcolor {
	color:#BF2726 !important;
}
.miniessayspacer p {
	border-bottom: 1px solid #E4E2E7;
	padding-bottom: 0px;
	padding-left: 10px;
	padding-top: 5px;
}
.miniessaypopupplace {
	position:relative;
}
.miniessaypopup {
	position:absolute;
	z-index:44;
	margin-top:30px;
	border:solid 1px;
}
.miniessaypopupmessage {
	background-color:#FFFFFF;
	width:305px;
	position:absolute;
	z-index:50;
	margin-top:25px;
}
.miniessaypopuparrow {
	margin-left:30px;
	margin-left:230px;
	position:absolute;
	z-index:56;
	margin-top:-4px;
}
.miniessaypopupmessage p {
	font-size:14px;
	line-height:22px;
	width:275px;
	margin-left:15px;
	margin-top:15px;
	margin-bottom:15px;
}
.miniessayclosebtn {
	position:absolute;
	z-index:58;
	margin-left:292px;
	margin-top:11px;
}


.profileproeditbottom {
	background-color:#f3f3f3;
	
}

.profileproeditnophotoright {
	background-color:#f3f3f3;
}

.h1headerprofilepro {
	color: #BF2726;
	
}

.h1headerprofileprooneclick {
	color: #BF2726;
	
}


.rtnmessaging p {color:#FFFFFF;}

.connectbox  h2 a {color:#1d5caa !important;}

#connectarea h1 {color:#3d3d3d !important;}

.profilesolophotoview {background-color:#f3f3f3 !important;}

.profilesolophotoimgcount {color:#3d3d3d !important;}

.crosssellofferoptions {border-color:#1d5caa !important;}

.crosssellreceipt {border-color:#1d5caa !important;}
/* ------------ end 24/theme.css ------------ */
